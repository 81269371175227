<template>
    <router-view></router-view>
</template>
<script>
    import limitationStoreModule from './limitationStoreModule.js';
    import store from '@/store';
    import { onUnmounted } from '@vue/composition-api';

    export default {
        setup() {
            const LIMITATION_APP_STORE_MODULE_NAME = 'app-limitation';

            // Register module
            if (!store.hasModule(LIMITATION_APP_STORE_MODULE_NAME))
                store.registerModule(LIMITATION_APP_STORE_MODULE_NAME, limitationStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(LIMITATION_APP_STORE_MODULE_NAME))
                    store.unregisterModule(LIMITATION_APP_STORE_MODULE_NAME);
            });
        },
    };
</script>
