import axios from '@axios';
import qs from 'qs';
import _ from 'lodash';

const initialQuery = () => {
    return {
        sort: ['id:asc'],
        filters: {
            // users: {
            //     id: {
            //         $containsi: '',
            //     },
            // },
            $or: [
                {
                    name: {
                        $containsi: '',
                    },
                },
                {
                    description: {
                        $containsi: '',
                    },
                },
                {
                    users: {
                        id: {
                            $containsi: '',
                        },
                    },
                },
            ],
        },
        populate: ['users'],
        pagination: {
            page: 1,
            pageSize: 5,
        },
    };
};

export default {
    namespaced: true,
    state: {
        limitations: [],
        query: initialQuery(),
        isDeleteLimitation: false,
    },
    getters: {
        getLimitationList(state) {
            return state.limitations;
        },
        getLimitationQuery(state) {
            return state.query;
        },
        getLimitation(state) {
            return state.limitation;
        },
        getIsDeleteLimitation(state) {
            return state.isDeleteLimitation;
        },
    },
    mutations: {
        setLimitationList(state, limitations) {
            state.limitations = limitations;
        },
        setLimitationQuery(state, query) {
            state.query = query;
        },
        resetLimitationQuery(state) {
            state.query = initialQuery();
        },
        setLimitation(state, limitationData) {
            state.limitationData = limitationData;
        },
        setLimitationSearch(state, search) {
            state.query.filters.$or[0].name.$containsi = search;
            state.query.filters.$or[1].description.$containsi = search;
            state.query.filters.$or[2].users.id.$containsi = search;
        },
        setIsDeleteLimitation(state, bol) {
            state.isDeleteLimitation = bol;
        },
    },
    actions: {
        fetchLimitationList(ctx) {
            let _query = _.cloneDeep(ctx.state.query);

            let query = qs.stringify(_query);

            return new Promise((resolve, reject) => {
                axios
                    .get('/limitations?' + query)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        fetchLimitation(ctx, id, query = {}) {
            query.populate = ['users'];

            return new Promise((resolve, reject) => {
                axios
                    .get(`/limitations/${id}`, { params: query })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        updateLimitation(ctx, data) {
            let query = qs.stringify({
                populate: ['users'],
            });
            return new Promise((resolve, reject) => {
                let _data = _.assign({}, data);
                let id = _data.id;
                delete _data.id;
                axios
                    .put(`/limitations/${id}?${query}`, { data: _data })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        addLimitation(ctx, limitationData) {
            limitationData = _.assign({}, limitationData);

            return new Promise((resolve, reject) => {
                axios
                    .post('/limitations', { data: limitationData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        deleteLimitation(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/limitations/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        deleteManyLimitations(ctx, query = {}) {
            query = qs.stringify(query);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/limitations/many?${query}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        /*         
export function deleteManyItems(query = {}) {
    query = qs.stringify(query);
    return axios.delete(`/items/many?${query}`);
} */
        /*   addSections(ctx, sectionsData) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/parking-lots-sections', { data: sectionsData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        }, */
    },
};
